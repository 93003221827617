import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './Header.module.scss';
import logo from '../../assets/logo.svg'

//header

function Header() {
  const {t} = useTranslation();

    return (
      <div className={styles.header}>
        <div className={styles.header__container}>
          <img className={styles.header__logo} src={logo} alt="logo" />
          <div className={styles.header__title}>{t('headerTitle')}</div>
        </div>
      </div>
    );
}
  
export default Header;