import { FaPlaystation, FaSteam, FaTwitch } from "react-icons/fa";

export const imageUrl = 'https://www.bungie.net';

export const ClassTypes = {
    0: "Titan",
    1: "Hunter",
    2: "Warlock",
    3: "Unknown"
};

export const PlatformIcons = {
    Steam: <FaSteam style={{color: "#FFFFFFCC"}} />,
    Twitch: <FaTwitch style={{color: "#FFFFFFCC"}} />,
    Psn: <FaPlaystation style={{color: "#FFFFFFCC"}} />
}