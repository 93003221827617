import React from 'react';

import styles from './Button.module.scss';


function Button({title, onClick, width}) {
    return (
      <button style={{width}} className={styles.button} onClick={onClick}>
        {title}
      </button>
    );
}
  
export default Button;