import React from 'react';

import styles from './RaidInfo.module.scss';
import Statuses from '../../models/Statuses';


function RaidClear({status}) {
    return (
        <div className={status === Statuses.INCOMPLETE ? styles.raid__clear__incomplete : status === Statuses.FLAWLESS ? styles.raid__clear__flawless : styles.raid__clear__success}>&nbsp;</div>
    );
}

export default RaidClear;