import React from 'react';

import styles from './RaidInfo.module.scss';


function RaidBadge({text}) {
    return (
        <div className={styles.raid__badge}>
            {text}
        </div>
    );
}

export default RaidBadge;