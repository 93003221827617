import React from 'react';

import styles from './InfoBlock.module.scss';


function InfoBlock({ title, className, children }) {
    return (
        <div className={`${className ? className + ' ' : ""}${styles.player__info__block}`}>
            <div className={styles.player__info__title}>
                {title}
            </div>
            <div className={styles.player__info__data}>
                {children}
            </div>
        </div>
    );
}

export default InfoBlock;