import React from 'react';

import Header from '../../components/Header/Header';
import { Outlet } from 'react-router-dom';
import styles from './Layout.module.scss';

const Layout = () => {
    return (
      <>
        <Header />
        <div className={styles.content_wrapper}>
          <Outlet />
        </div>
      </>
    );
}
  
export default Layout;