import React from 'react';

import styles from './RaidInfo.module.scss';
import raidImage from '../../assets/raid-image.png';
import tickIcon from '../../assets/tick.svg';
import crossIcon from '../../assets/cross.svg';
import RaidBadge from './RaidBadge';
import RaidClear from './RaidClear';
import InfoBlock from '../InfoBlock/InfoBlock';
import { useTranslation } from 'react-i18next';


function RaidInfo() {
    const { t } = useTranslation();

    return (
        <div className={styles.raid}>
            <img src={raidImage} alt="raid" className={styles.raid__image} />
            <div className={styles.raid__badges}>
                <RaidBadge text={'Solo'.toUpperCase()} />
                <RaidBadge text={'Flawless Trio'.toUpperCase()} />
                <RaidBadge text={'Challenge day one'.toUpperCase()} />
            </div>
            <div className={styles.raid__name}>Root of Nightmares</div>
            <div className={styles.raid__clears}>
                <RaidClear status='incomplete' />
                <RaidClear status='incomplete' />
                <RaidClear status='success' />
                <RaidClear status='flawless' />
                <RaidClear status='flawless' />
                <RaidClear status='success' />
                <RaidClear status='incomplete' />
                <RaidClear status='success' />
                <RaidClear status='success' />
                <RaidClear status='success' />
            </div>
            <div className={styles.raid__data_row}>
                <div className={styles.raid__data_row__name}>{t('totalClears')}</div>
                <div className={styles.raid__data_row__value}>482 736</div>
            </div>
            <div className={styles.raid__data_row}>
                <div className={styles.raid__data_row__name}>{t('fastest')}</div>
                <div className={`${styles.raid__data_row__value} ${styles.raid__fastest}`}>12d 45h 30m 15s</div>
            </div>
            <div className={styles.raid__tabs}>
                <div className={styles.raid__tab__active}>{t('normal').toUpperCase()}</div>
                <div className={styles.raid__tab}>{t('master').toUpperCase()}</div>
                <div className={styles.raid__tab}>{t('guided').toUpperCase()}</div>
            </div>
            <div className={styles.raid__tabs__content}>
                <div className={styles.raid__data_row}>
                    <div className={styles.raid__data_row__name}>{t('clears')}</div>
                    <div className={styles.raid__data_row__value}>999,999</div>
                </div>
                <div className={styles.raid__data_row}>
                    <div className={styles.raid__data_row__name}>{t('fresh')}</div>
                    <div className={styles.raid__data_row__value}>999,999</div>
                </div>
                <div className={styles.raid__data_row}>
                    <div className={styles.raid__data_row__name}>{t('looted')}</div>
                    <div className={styles.raid__data_row__value}>999,999</div>
                </div>
                <div className={styles.raid__data_row}>
                    <div className={styles.raid__data_row__name}>{t('fastest')}</div>
                    <div className={`${styles.raid__data_row__value} ${styles.raid__fastest}`}>99d 23h 59m 59s</div>
                </div>
            </div>
            <InfoBlock title={t('characters').toUpperCase()}>
                <div className={styles.raid__characters}>
                    <div className={styles.raid__data_row}>
                        <div className={styles.raid__data_row__name}>{t('warlock')}:</div>
                        <div className={`${styles.raid__data_row__value} ${styles.raid__fastest}`}><img src={tickIcon} alt='tick icon'/></div>
                    </div>
                    <div className={styles.raid__data_row}>
                        <div className={styles.raid__data_row__name}>{t('titan')}:</div>
                        <div className={`${styles.raid__data_row__value} ${styles.raid__fastest}`}><img src={tickIcon} alt='tick icon'/></div>
                    </div>
                    <div className={styles.raid__data_row}>
                        <div className={styles.raid__data_row__name}>{t('warlock')}:</div>
                        <div className={`${styles.raid__data_row__value} ${styles.raid__fastest}`}><img src={crossIcon} alt='cross icon'/></div>
                    </div>
                </div>
            </InfoBlock>
        </div>
    );
}

export default RaidInfo;