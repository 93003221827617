import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './Player.module.scss';
import backgroundLogo from '../../assets/search-background.svg';
import logo from '../../assets/logo.svg';
import InfoBlock from '../../components/InfoBlock/InfoBlock';
import RaidInfo from '../../components/RaidInfo/RaidInfo';
import Button from '../../components/Button/Button';
import { getCharacterData, getClanData, getLinkedAccounts, searchUsers } from '../../services/raidReportService';
import { imageUrl, PlatformIcons } from '../../common/constants';
import getClassTypeById from '../../common/utils';

function Player() {
    const { t } = useTranslation();

    const [searchInput, setSearchInput] = useState("");
    const [isFocused, setIsFocused] = useState(false);
    const [user, setUser] = useState({});
    const [clan, setClan] = useState({});
    const [characterData, setCharacterData] = useState({});
    const [linkedAccounts, setLinkedAccounts] = useState({});

    const handleSearchPressEnter = (event) => {
        if (event.key === 'Enter') {
           searchUser()
        }
    }

    const handleInputChange = (event) => {
        setSearchInput(event.target.value);
    }

    const searchUser = async () => {
        const searchResults = await searchUsers(searchInput)
        setUser(searchResults[0])
    }

    const handleOnFocus = () => {
        setIsFocused(true);
    };

    const handleOnBlur = () => {
        setIsFocused(false);
    };

    const getIconByPlatformType = (platformKey) => {
        const platformName = platformKey.match(/^(.+)id$/i)
        return PlatformIcons[platformName[1]];
    };

    useEffect(() => {
        const fetchClanData = async () => {
            const clanData = await getClanData(user.membershipId, user.membershipType);
            setClan(clanData);
        }

        if (user.membershipId) {
            fetchClanData();
        }
    }, [user])

    useEffect(() => {
        const fetchCharacterData = async () => {
            const characterData = await getCharacterData(user.membershipId, user.membershipType);
            setCharacterData(characterData);
        }

        if (user.membershipId) {
            fetchCharacterData();
        }
    }, [user])

    useEffect(() => {
        const fetchLinkedAccountNames = async () => {
            const linkedAccounts = await getLinkedAccounts(user.membershipId, user.membershipType)
            setLinkedAccounts(linkedAccounts);
        }

        if (user.membershipId) {
            fetchLinkedAccountNames();
        }
    }, [user])


    const playerInfo = user.membershipId &&
        (<div className={styles.player}>
            <div className={styles.player__container}>
                <div className={styles.player__info}>
                    <InfoBlock title={t('guardianInfo').toUpperCase()}>
                        <div className={styles.player__info__name_tag}>{user.bungieGlobalDisplayName ?? 'Name'}<span className={styles.player__info__tag}>#{user.bungieGlobalDisplayNameCode ?? '9999'}</span></div>
                    </InfoBlock>
                    <InfoBlock title={t('clan').toUpperCase()}>
                        <div className={`${styles.player__info__clan_name} ${styles.player__info__data__row}`}>{clan.name ?? t('clanNamePlaceholder')}</div>
                        <div className={`${styles.player__info__clan_slogan} ${styles.player__info__data__row}`}>{clan.motto ?? t('clanMottoPlaceholder')}</div>
                        <div className={`${styles.player__info__clan_info} ${styles.player__info__data__row}`}>{clan.about ?? t('clanAboutPlaceholder')}</div>
                        <div className={`${styles.player__info__clan_more} ${styles.player__info__data__row}`}>more</div>
                    </InfoBlock>
                    <InfoBlock title={t('characters').toUpperCase()}>
                        {Object.entries(characterData).map(([charId, charInfo], index) => (
                            <div key={index} className={styles.player__info__character_card} style={{ backgroundImage: `url(${imageUrl + charInfo.emblemBackgroundPath})` }}>
                                <img className={styles.player__info__character_logo} src={imageUrl + charInfo.emblemPath} alt="character logo" />
                                <div className={styles.player__info__character_card__type}>{getClassTypeById(charInfo.classType)}</div>
                                <div className={styles.player__info__character_card__light_level}>
                                    <img src={logo} alt="light level icon" className={styles.player__info__character_card__light_level__icon} />
                                    {charInfo.light}
                                </div>
                            </div>
                        ))}
                    </InfoBlock>
                    <InfoBlock title={t('linkedAccounts').toUpperCase()}>
                        {Object.entries(linkedAccounts).map(([platformId, accountName], index) => (
                            <div className={styles.player__info__linked_accounts} key={index}>
                                {getIconByPlatformType(platformId)} {accountName}
                            </div>
                        ))}
                    </InfoBlock>
                    <InfoBlock title={t('links').toUpperCase()}>
                        <a className={styles.player__info__cohort_link} href={`https://www.bungie.net/7/en/User/Profile/${user?.membershipType}/${user?.membershipId ?? ""}`} target='_blank' rel='noreferrer'>Bungie.net</a>
                        <a className={styles.player__info__cohort_link} href={`https://raid.report/pc/${user?.membershipId ?? ""}`} target='_blank' rel='noreferrer'>Raid.Report</a>
                        <a className={styles.player__info__cohort_link} href={`https://raidhub.io/profile/${user?.membershipId ?? ""}`} target='_blank' rel='noreferrer'>RaidHub</a>
                        <a className={styles.player__info__cohort_link} href={`https://guardian.report/?view=ALL&guardians=${user?.membershipId ?? ""}`} target='_blank' rel='noreferrer'>Guardian.Report</a>
                    </InfoBlock>
                </div>
                <InfoBlock className={styles.player__raids} title={t('raids').toUpperCase()}>
                    <div className={styles.player__raids__content}>
                        <RaidInfo />
                        <RaidInfo />
                        <RaidInfo />
                        <RaidInfo />
                        <RaidInfo />
                        <RaidInfo />
                        <RaidInfo />
                    </div>
                </InfoBlock>
            </div>
        </div>)

    return (
        <>
            <div className={styles.search}>
                <div className={styles.search__container}>
                    <div className={styles.search__content}>
                        <div className={styles.search__header}>{t('searchHeader')}</div>
                        <div className={styles.search__controls}>
                            <div className={isFocused ? styles.search__input__focused : styles.search__input}>
                                <input
                                    type="text"
                                    onFocus={handleOnFocus}
                                    onBlur={handleOnBlur}
                                    onChange={handleInputChange}
                                    onKeyDown={handleSearchPressEnter}
                                    value={searchInput}
                                    className={styles.search__input__content}
                                    placeholder={t('searchPlaceholder')}
                                />
                            </div>
                            <Button title={t('search')} width={128} onClick={searchUser} />
                        </div>
                    </div>
                    <img src={backgroundLogo} alt="background-logo" />
                </div>
            </div>
            {playerInfo}
        </>
    );
}

export default Player;