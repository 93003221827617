import { BrowserRouter, Routes, Route } from "react-router-dom";

import Layout from './pages/Layout/Layout';
import Player from './pages/Player/Player';

function App() {
  return (
    <div className="app">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Player />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
