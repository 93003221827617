import axios from "axios";

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {'X-API-Key': process.env.REACT_APP_API_KEY}
});

const searchUsers = async (query) => {
    return await axiosInstance.get(`/Destiny2/SearchDestinyPlayer/-1/${encodeURIComponent(query)}/`)
        .then(res => res.data)
        .then(data => data.Response);
}

const getClanData = async(membershipId, membershipType) => {
    return await axiosInstance.get(`GroupV2/User/${membershipType}/${membershipId}/0/1/`)
        .then(res => res.data)
        .then(data => data.Response.results[0].group);
}

const getCharacterData = async(membershipId, membershipType) => {
    return await axiosInstance.get(`Destiny2/${membershipType}/Profile/${membershipId}/?components=100,200`)
        .then(res => res.data)
        .then(data => data.Response.characters.data);
}

const getLinkedAccounts = async(membershipId) => {
    return await axiosInstance.get(`User/GetSanitizedPlatformDisplayNames/${membershipId}/`)
        .then(res => res.data)
        .then(data => data.Response);
}

export {searchUsers, getClanData, getCharacterData, getLinkedAccounts};